const TwitterIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='icon icon-twitter'
    >
      <g clipPath='url(#clip_twitter)'>
        <path
          d='M5.66262 16.3125C12.4534 16.3125 16.1687 10.685 16.1687 5.8064C16.1687 5.64819 16.1652 5.48647 16.1582 5.32827C16.8809 4.8056 17.5046 4.15819 18 3.41647C17.3269 3.71595 16.6122 3.91154 15.8804 3.99655C16.651 3.53468 17.2279 2.8091 17.5043 1.95433C16.7794 2.38392 15.9867 2.68696 15.1601 2.85046C14.6031 2.25867 13.8667 1.86684 13.0648 1.73554C12.2628 1.60424 11.4399 1.74079 10.7233 2.12407C10.0067 2.50736 9.43635 3.11603 9.10039 3.85598C8.76442 4.59594 8.68157 5.42596 8.86465 6.21772C7.39687 6.14407 5.96095 5.76278 4.64999 5.09857C3.33902 4.43437 2.18227 3.50208 1.25473 2.36214C0.7833 3.17493 0.639042 4.13673 0.851273 5.05207C1.0635 5.9674 1.6163 6.76759 2.3973 7.28999C1.81097 7.27138 1.23748 7.11351 0.724219 6.82944V6.87515C0.723693 7.72811 1.01857 8.55494 1.55873 9.21508C2.09889 9.87522 2.85099 10.3279 3.68719 10.4962C3.14404 10.6448 2.57399 10.6665 2.02113 10.5595C2.25709 11.2931 2.71618 11.9347 3.33433 12.3948C3.95248 12.8548 4.69884 13.1104 5.46926 13.1259C4.16132 14.1533 2.54563 14.7106 0.882422 14.708C0.587465 14.7075 0.292799 14.6894 0 14.6538C1.68964 15.7378 3.65515 16.3135 5.66262 16.3125Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip_twitter'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TwitterIcon
