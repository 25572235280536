const WebsiteIcon = () => {
  return (
    <svg
      className='icon icon-website'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 15.1132L10.1708 13.24C10.7974 12.2373 11.1819 11.1305 11.3241 10H6.67589C6.81813 11.1305 7.20259 12.2373 7.82924 13.24L9 15.1132ZM10.9821 15.7154C13.5729 14.9519 15.5386 12.7301 15.9291 10H13.3367C13.1862 11.4997 12.6962 12.9729 11.8668 14.3L10.9821 15.7154ZM13.3367 8H15.9291C15.5386 5.26995 13.5729 3.04805 10.9821 2.28458L11.8668 3.70001C12.6962 5.02715 13.1862 6.50032 13.3367 8ZM11.3241 8H6.67589C6.81813 6.8695 7.20259 5.76266 7.82924 4.76001L9 2.8868L10.1708 4.76001C10.7974 5.76266 11.1819 6.8695 11.3241 8ZM6.13325 14.3L7.01789 15.7154C4.42715 14.9519 2.46139 12.7301 2.07089 10H4.66328C4.8138 11.4997 5.30379 12.9729 6.13325 14.3ZM6.13325 3.70001L7.01789 2.28458C4.42715 3.04805 2.46139 5.26995 2.07089 8H4.66328C4.8138 6.50032 5.30379 5.02715 6.13325 3.70001ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z'
        fill='white'
      />
    </svg>
  )
}

export default WebsiteIcon
