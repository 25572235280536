const GemIcon = () => {
  return (
    <svg
      className='icon icon-gem'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.32354 2.77422L0.471591 5.59398C0.169617 5.89632 0 6.30616 0 6.73347C0 7.16079 0.169617 7.57063 0.471591 7.87297L1.26559 8.66696L7.61542 2.30426H4.46303C4.03608 2.30446 3.6265 2.47338 3.32354 2.77422Z'
        fill='url(#paint0_linear_1001_2538)'
      />
      <path
        d='M7.61545 2.30428L1.26562 8.66698L3.19697 10.5983L11.4932 2.29999L7.61545 2.30428Z'
        fill='url(#paint1_linear_1001_2538)'
      />
      <path
        d='M11.4935 2.30428L3.19727 10.5983L5.12861 12.5297L14.7531 2.90729L14.6952 2.85149C14.5282 2.67724 14.3276 2.53855 14.1056 2.44378C13.8836 2.349 13.6447 2.30009 13.4033 2.29999H11.4935V2.30428Z'
        fill='url(#paint2_linear_1001_2538)'
      />
      <path
        d='M14.7525 2.90729L5.12793 12.5297L7.06142 14.4632L16.7031 4.86009L14.7525 2.90729Z'
        fill='url(#paint3_linear_1001_2538)'
      />
      <path
        d='M7.8126 15.2143L7.06152 14.4632L16.7032 4.86011L17.4994 5.65625C18.1281 6.28501 18.1839 7.24424 17.5552 7.873L10.1774 15.2164C9.86333 15.5293 9.43793 15.7048 8.99457 15.7044C8.55121 15.704 8.12613 15.5277 7.8126 15.2143Z'
        fill='url(#paint4_linear_1001_2538)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1001_2538'
          x1='-0.247727'
          y1='8.24679'
          x2='6'
          y2='2'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.86' stopColor='white' stopOpacity='0.14' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1001_2538'
          x1='2.06069'
          y1='9.83137'
          x2='6.04355'
          y2='5.82147'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.9' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1001_2538'
          x1='7.6106'
          y1='8.0805'
          x2='13.5'
          y2='2.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1001_2538'
          x1='6'
          y1='13.5'
          x2='14.2647'
          y2='5.45559'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.58' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1001_2538'
          x1='9.50596'
          y1='13.9387'
          x2='17.5'
          y2='6'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.51' stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GemIcon
