import PropTypes from 'prop-types'

const Title = ({ children, color, level, className }) => {
  const classNameTitle =
    'title' +
    (color ? ` title_color_${color}` : '') +
    (className ? ` ${className}` : '')

  switch (level) {
    case 1: {
      return <h1 className={classNameTitle}>{children}</h1>
    }
    case 2: {
      return <h2 className={classNameTitle}>{children}</h2>
    }
    case 4: {
      return <h4 className={classNameTitle}>{children}</h4>
    }
    case 5: {
      return <h5 className={classNameTitle}>{children}</h5>
    }
    default: {
      return <h3 className={classNameTitle}>{children}</h3>
    }
  }
}

PropTypes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  level: PropTypes.number.isRequired,
  color: PropTypes.oneOf(['default', 'rainbow']),
}

export default Title
