const LooksrareIcon = () => {
  return (
    <svg
      className='icon icon-looksrare'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00042 10.4595C7.45614 10.4595 6.20312 9.2077 6.20312 7.66217C6.20312 6.11663 7.45614 4.86487 9.00042 4.86487C10.5447 4.86487 11.7977 6.11663 11.7977 7.66217C11.7977 9.2077 10.5447 10.4595 9.00042 10.4595ZM7.78421 7.66217C7.78421 8.33414 8.329 8.87838 9.00042 8.87838C9.67185 8.87838 10.2166 8.33414 10.2166 7.66217C10.2166 6.99019 9.67185 6.44595 9.00042 6.44595C8.329 6.44595 7.78421 6.99019 7.78421 7.66217Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 7.66586L5.35135 2.31081H12.6486L18 7.66586L9 16.6622L0 7.66586ZM13.1351 5.7162C10.8614 3.43246 7.13862 3.43247 4.86486 5.71621L2.91892 7.66218L4.86486 9.60814C7.13862 11.8919 10.8614 11.8919 13.1351 9.60812L15.0811 7.66218L13.1351 5.7162Z'
        fill='white'
      />
    </svg>
  )
}

export default LooksrareIcon
