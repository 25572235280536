const InstagramIcon = () => {
  return (
    <svg
      className='icon icon-instagram'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 2H8C6.56687 2 5.6123 2.00156 4.87887 2.06148C4.1688 2.11949 3.84387 2.2221 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2.2221 3.84387 2.11949 4.1688 2.06148 4.87887C2.00156 5.6123 2 6.56687 2 8V10C2 11.4331 2.00156 12.3877 2.06148 13.1211C2.11949 13.8312 2.2221 14.1561 2.32698 14.362C2.6146 14.9265 3.07354 15.3854 3.63803 15.673C3.84387 15.7779 4.1688 15.8805 4.87887 15.9385C5.6123 15.9984 6.56687 16 8 16H10C11.4331 16 12.3877 15.9984 13.1211 15.9385C13.8312 15.8805 14.1561 15.7779 14.362 15.673C14.9265 15.3854 15.3854 14.9265 15.673 14.362C15.7779 14.1561 15.8805 13.8312 15.9385 13.1211C15.9984 12.3877 16 11.4331 16 10V8C16 6.56687 15.9984 5.6123 15.9385 4.87887C15.8805 4.1688 15.7779 3.84387 15.673 3.63803C15.3854 3.07354 14.9265 2.6146 14.362 2.32698C14.1561 2.2221 13.8312 2.11949 13.1211 2.06148C12.3877 2.00156 11.4331 2 10 2ZM0.544967 2.73005C0 3.79961 0 5.19974 0 8V10C0 12.8003 0 14.2004 0.544967 15.27C1.02433 16.2108 1.78924 16.9757 2.73005 17.455C3.79961 18 5.19974 18 8 18H10C12.8003 18 14.2004 18 15.27 17.455C16.2108 16.9757 16.9757 16.2108 17.455 15.27C18 14.2004 18 12.8003 18 10V8C18 5.19974 18 3.79961 17.455 2.73005C16.9757 1.78924 16.2108 1.02433 15.27 0.544967C14.2004 0 12.8003 0 10 0H8C5.19974 0 3.79961 0 2.73005 0.544967C1.78924 1.02433 1.02433 1.78924 0.544967 2.73005ZM9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11ZM9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13ZM14 6C14.5523 6 15 5.55228 15 5C15 4.44772 14.5523 4 14 4C13.4477 4 13 4.44772 13 5C13 5.55228 13.4477 6 14 6Z'
        fill='white'
      />
    </svg>
  )
}

export default InstagramIcon
