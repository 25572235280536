const DiscordIcon = () => {
  return (
    <svg
      className='icon icon-discord'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.2601 3.32388C14.0866 2.78719 12.8404 2.39605 11.5396 2.17773C11.3759 2.45972 11.194 2.84177 11.0666 3.14195C9.68307 2.94183 8.30951 2.94183 6.94504 3.14195C6.81769 2.84177 6.62666 2.45972 6.47201 2.17773C5.16216 2.39605 3.91595 2.78719 2.7507 3.32388C0.394744 6.79869 -0.242005 10.1916 0.0763687 13.5391C1.64094 14.6761 3.15096 15.3675 4.63458 15.8223C4.99844 15.3311 5.32591 14.8035 5.6079 14.2486C5.07121 14.0485 4.56181 13.8029 4.07061 13.5118C4.19796 13.4209 4.32531 13.3208 4.44356 13.2208C7.40898 14.5761 10.6209 14.5761 13.5499 13.2208C13.6773 13.3208 13.7955 13.4209 13.9229 13.5118C13.4317 13.8029 12.9223 14.0485 12.3856 14.2486C12.6676 14.8035 12.9951 15.3311 13.3589 15.8223C14.8416 15.3675 16.3607 14.6761 17.9171 13.5391C18.3082 9.66407 17.2976 6.29842 15.2601 3.32388ZM6.01723 11.4742C5.12577 11.4742 4.39806 10.6647 4.39806 9.67317C4.39806 8.68167 5.10758 7.87205 6.01723 7.87205C6.91775 7.87205 7.65454 8.68167 7.63634 9.67317C7.63634 10.6647 6.91775 11.4742 6.01723 11.4742ZM11.9945 11.4742C11.103 11.4742 10.3744 10.6647 10.3744 9.67317C10.3744 8.68167 11.0848 7.87205 11.9945 7.87205C12.895 7.87205 13.6318 8.68167 13.6136 9.67317C13.6136 10.6647 12.9041 11.4742 11.9945 11.4742Z'
        fill='white'
      />
    </svg>
  )
}

export default DiscordIcon
